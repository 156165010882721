import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { Link } from 'gatsby'

import '../../styles/quienes.scss'

import simonImg from '../../images/simon.png'
import angelaImg from '../../images/angela.png'

export default function QuienesSomos({ children, location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="¿Quiénes Somos? | Sobre Nosotros" />
			<div className="quienes">
				<Link to="/#nosotros" className="back">
					&larr;Volver
				</Link>
				<div className="quienes__title">
					<div className="quienes__texture" />
					<h1>¿QUIÉNES SOMOS?</h1>
				</div>
				<div className="quienes__content">
					<div className="quienes__content__leftbar" />
					<div className="quienes__inner-content">
						<h2>LO QUE NOS INSPIRA</h2>
						<p>
							Hacer mejor los lugares que habitamos pensando en el futuro que queremos dejarle a nuestros
							hijos, es lo que mueve a Grupo Ínsula. No solamente los espacios y el diseño hacen un mundo
							más habitable, sino también los buenos principios, los buenos actos, cultivar el amor
							propio, por los demás y por la naturaleza son bases fundamentales para lograrlo.
						</p>
					</div>
					<div className="quienes__content__rightbar" />
				</div>
				<div className="quienes__photos">
					<div className="quienes__simon">
						<img src={simonImg} alt="Grupo Insula - Simón Sánchez" />
						<Link to="/nosotros/simon">SIMÓN</Link>
					</div>
					<div className="quienes__angela">
						<img src={angelaImg} alt="Grupo Insula - Angela Patruno" />
						<Link to="/nosotros/angela">ÁNGELA</Link>
					</div>
				</div>
			</div>
		</Layout>
	)
}
